<template>
	<div class="wraper flexCloumnSingleCenter sys_menu">
    <el-row style="width: 100%;">
      <el-button icon="el-icon-plus" size="small" type="primary"
                 @click="handleAdd">
        新增菜单</el-button>
    </el-row>
		
		<div class="flex_1" style="width: 100%;margin-top: 10px;">
      <div class="" style="width: 100%;height: 100%;overflow: hidden">
        <el-table :data="tableData" height="100%" style="width: 100%;height:100%;" row-key="id" border stripe
                  default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="title" label="名称" min-width="180">
            <template slot-scope="scope">
              <i v-if="!scope.row.iconurl0&&scope.row.icon" :class="scope.row.icon"/>
              <img v-if="scope.row.iconurl0" :src="scope.row.iconurl0" style="width: 15px;height: 15px;vertical-align: middle;"/>
              <span style="margin-left: 4px;vertical-align: middle;"> {{ scope.row.title }}</span>
            </template>
          </el-table-column>


          <el-table-column prop="path" label="路径">
          </el-table-column>
                                  <el-table-column
                                      prop="is_show" label="状态">
                                      <template slot-scope="scope">
                                          <span>{{scope.row.is_show=='1'?'已启用':'已隐藏'}}</span>
                                      </template>
                                  </el-table-column>

          <el-table-column prop="sort" label="排序" width="100">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
                             icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)"
                             placement="left" title="是否确定删除？">
                <a slot="reference" size="small"
                   style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
              </el-popconfirm>
              <el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
              <el-button @click="handleAdd(scope.row)" type="text" size="small">添加下级</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="formData" :rules="rules" ref="adminUserInfo" label-width="120px"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">
				<el-form-item v-if="formData.pid!=0" label="上级菜单" prop="pid">
<!--					<el-cascader ref="firstCascader" :options="tableData" :disabled="modalTitle=='修改'"-->
<!--						:props="defaultProps" v-model="formData.pid" :show-all-levels="false"-->
<!--						@change="handleCascaderChange" placeholder="请选择" filterable style="width: 50%">-->
<!--					</el-cascader>-->
          <el-cascader ref="firstCascader" :options="tableData"
                       :props="defaultProps" v-model="formData.pid" :show-all-levels="false"
                       @change="handleCascaderChange" placeholder="请选择" filterable style="width: 50%">
          </el-cascader>
				</el-form-item>
				<el-form-item label="菜单名称" prop="title">
					<el-input v-model.trim="formData.title" style="width: 50%" />
				</el-form-item>
				<el-form-item label="菜单类型" prop="mtype">
					<el-select v-model="formData.mtype" @change="$forceUpdate()" placeholder="请选菜单类型"
						style="width: 50%">
						<el-option label="页面" value="page">页面</el-option>
						<el-option label="按钮" value="button">按钮</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="菜单图标" prop="icon">
					<el-select v-model="formData.icon" placeholder="请选择" style="width: 50%">
						<el-option v-for="(item,index) in iconGroup" :key="index" :label="item" :value="item">
							<template slot-scope="scope">
								<span class="svg-container">
									<i :class="item" />
								</span>
								<span style="margin-left: 5px;">{{item}}</span>
							</template>
						</el-option>
					</el-select>
				</el-form-item>

        <el-form-item class="" label="菜单图标0" prop="icon">
          <div class="flexStart">
            <div class="thumbnail flexCenter" style="width: 60px;height: 60px;border: 1px dashed #ccc;">
              <img v-if="formData.iconurl0" :src="formData.iconurl0" @click="handlePictureCardPreview(formData.iconurl0)"
                   alt="" style="max-width: 100%;max-height: 100%;cursor: pointer;">
            </div>
            <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
                       :on-success="e=>uploadFiles(e,0)"
                       :show-file-list="false" name="image">
              <el-button title="上传图片" type="primary" icon="el-icon-picture-outline-round" size="mini" plain
                         class="flexCenter" style="margin-left: 20px;font-size: 14px;padding: 8px;">选择图标</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item class="" label="菜单图标1" prop="icon">
          <div class="flexStart">
            <div class="thumbnail flexCenter" style="width: 60px;height: 60px;border: 1px dashed #ccc;">
              <img v-if="formData.iconurl1" :src="formData.iconurl1" @click="handlePictureCardPreview(formData.iconurl1)"
                   alt="" style="max-width: 100%;max-height: 100%;cursor: pointer;">
            </div>
            <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
                       :on-success="e=>uploadFiles(e,1)"
                       :show-file-list="false" name="image">
              <el-button title="上传图片" type="primary" icon="el-icon-picture-outline-round" size="mini" plain
                         class="flexCenter" style="margin-left: 20px;font-size: 14px;padding: 8px;">选择图标</el-button>
            </el-upload>
          </div>
        </el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input v-model.trim="formData.sort" style="width: 50%" />
				</el-form-item>
				<el-form-item label="路径" prop="path">
					<el-input v-model.trim="formData.path" style="width: 50%" />
				</el-form-item>
        <el-form-item label="是否启用" prop="is_show">
          <el-switch v-model="formData.is_show"
                     active-value="1"
                     inactive-value="0"/> {{formData.is_show=='1'?'已启用':'已隐藏'}}
        </el-form-item>
			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>
    <!--预览图片-->
    <el-dialog width="400px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
	</div>
</template>

<script>
	import api from '@/api/api';

	export default {
		name: 'Menu',
		data() {
			return {
				visible: false,
				isShowModal: false,
				modalTitle: '添加',
				formData: {
          is_show:'1',
        },
				defaultProps: {
					label: 'title',
					value: 'id',
					children: 'children',
					emitPath: false,
					checkStrictly: true
				},
				//table
				tabMapOptions: [{
					label: '菜单列表',
					key: 'CN'
				}, ],
				activeName: 'CN',
				tableData: [],
				iconGroup: [
					'el-icon-setting', 'el-icon-user', 'el-icon-help',
					'el-icon-camera', 'el-icon-video-camera', 'el-icon-bell',
					'el-icon-s-cooperation', 'el-icon-s-order', 'el-icon-s-operation',
					'el-icon-s-flag', 'el-icon-s-claim', 'el-icon-s-custom',
					'el-icon-s-opportunity', 'el-icon-s-data', 'el-icon-s-check',
					'el-icon-s-grid', 'el-icon-menu', 'el-icon-share',
					'el-icon-refresh', 'el-icon-finished',
					'el-icon-rank', 'el-icon-view', 'el-icon-date',
					'el-icon-edit-outline', 'el-icon-folder-opened', 'el-icon-folder-checked',
					'el-icon-tickets', 'el-icon-document-copy', 'el-icon-document',
					'el-icon-printer', 'el-icon-paperclip', 'el-icon-suitcase',
					'el-icon-pie-chart', 'el-icon-data-line', 'el-icon-collection-tag',
					'el-icon-bank-card', 'el-icon-price-tag', 'el-icon-guide',
					'el-icon-full-screen', 'el-icon-discover', 'el-icon-location-information',
				],
				rules: {
					title: [{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}, ],
				},
        dialogVisibleImageUrl: false,
        dialogImageUrl: '',//预览图片
			}
		},
		watch: {
			'formData.pid'() {
				if (this.$refs.firstCascader) {
					this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
				}
			},
		},
		mounted() {
			this.getList();
		},
		methods: {
			getList() {
				let _this = this
				this.$http.post(api.menuList).then(res => {
					if (res.data && res.data.length > 0) {
						_this.deleteEmptyGroup(res.data).then(result => {
							_this.tableData = result
						})
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise(resolve => {
					function traversal(data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},

			handleAdd(row) {
				this.isShowModal = true;
				this.formData = {}
				this.formData = {
					pid: row?row.id:0,
          is_show:'1',
        }
				this.modalTitle = '添加'
			},
			handleModify(row) {
				this.isShowModal = true;
				this.modalTitle = '修改';
				this.formData = row
        console.log(row)
			},
			handleDel(row) {
				this.$http.post(api.menuDel, {
					id: row.id
				}).then(res => {
					// if (res.code == 200) {
					// 	this.$message.success(res.msg)
					// 	this.getList();
					// }
					this.$message.success('删除成功')
					this.getList();
				})
			},
			handleCascaderChange(e) {
				console.log(1, e)
			},
			//开关
			switchChange(e) {
				this.formData.status = e
			},

			submitForm(formName) {
				let _this = this
				_this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post(api.menuSave, this.formData).then(res => {
							this.$message.success('保存成功')
							this.getList();
							this.isShowModal = false;
							return
							if (res.code == 200) {
								this.$message.success(res.msg)
								this.getList();
								this.isShowModal = false;
							} else {
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
      uploadFiles(e,i){
        let _this = this
        console.log(e.src)
        this.formData[`iconurl${i}`] = e.src
        this.formData = {..._this.formData}
      },
      handlePictureCardPreview(value) {
        let arr = value.split('.')
        let suffix = arr[arr.length - 1].toLocaleLowerCase()
        const imglist = ['svgz', 'pjp', 'png', 'ico', 'avif', 'tiff', 'tif', 'jfif', 'svg', 'xbm', 'pjpeg', 'webp', 'jpg', 'jpeg', 'bmp', 'gif'];
        let result = imglist.find(item => suffix.indexOf(item) > -1);
        if (result) {
          this.dialogImageUrl = value;
          this.dialogVisibleImageUrl = true;
        }
      },
		}
	}
</script>
<style scoped lang="less">
.sys_menu{
  padding-bottom: 10px;
}

</style>